// Bootstrap
$icon-font-path: '/fonts/';
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/select2/src/scss/core";
@import "node_modules/admin-lte/dist/css/AdminLTE";
@import "node_modules/admin-lte/dist/css/skins/skin-blue";

.cropit-preview {
  /* You can specify preview size in CSS */
  width: 500px;
  height: 300px;
  background: grey;
}
.cropit-image-zoom-input {
  width: 500px !important;
}

/* Translucent background image */
.cropit-preview-background {
  opacity: .2;
}

/*
 * If the slider or anything else is covered by the background image,
 * use relative or absolute position on it
 */
input.cropit-image-zoom-input {
  position: relative;
}

/* Limit the background image by adding overflow: hidden */
#image-cropper {
  overflow: hidden;
}
